<template>
	<el-dialog
		v-model="config.form.visible"
		v-if="!config.form.destroyed"
		title="Configuración"
		:center="true"
		:show-close="false"
		@closed="config.form.onClosed"
		:before-close="config.form.onBeforeClose"
	>
		<el-form :model="state.form.model" label-width="auto" :rules="config.form.rules" size="large" ref="formRef">
			<el-tabs v-model="config.form.activeTab" :stretch="true">
				<el-tab-pane label="Cambiar contraseña" :name="0">
					<el-form-item label="Contraseña actual" prop="clave_actual">
						<el-input v-model="state.form.model.clave_actual" placeholder="Ingresa contraseña actual" type="password" autocomplete="new-password" :validate-event="false"></el-input>
					</el-form-item>
					<el-form-item label="Contraseña nueva" prop="clave_nueva">
						<el-input v-model="state.form.model.clave_nueva" placeholder="Ingresa contraseña nueva" type="password" autocomplete="new-password" :validate-event="false"></el-input>
					</el-form-item>
					<el-form-item label="Confirma contraseña" prop="clave_nueva2">
						<el-input v-model="state.form.model.clave_nueva2" placeholder="Confirma contraseña" type="password" autocomplete="new-password" :validate-event="false"></el-input>
					</el-form-item>
				</el-tab-pane>
			</el-tabs>
		</el-form>
		<template #footer>
			<el-button type="danger" plain round @click="config.form.close">Cancelar</el-button>
			<el-button type="success" plain round @click="config.form.submit">Guardar</el-button>
		</template>
	</el-dialog>
</template>

<script setup>
	import { reactive, ref } from 'vue';
	import { ElLoading } from 'element-plus';

	// UTILS
	import { submit } from '@/utils';

	// VALIDATE CLAVE
	const validateClave = (rule, value, callback) => {
		if (state.form.model.clave_nueva != state.form.model.clave_nueva2) {
			callback(new Error('Confirmación de contraseña nueva no coincide'));
		} else {
			callback();
		}
	};

	// CAMBIAR
	const cambiar = () => {};

	// CONFIG
	const formRef = ref();
	const config = reactive({
		name: 'usuario',
		action: 'password',
		form: {
			visible: false,
			destroyed: false,
			activeTab: 0,
			close() {
				config.form.visible = false;
			},
			onClosed() {
				state.form.model = {
					clave_actual: '',
					clave_nueva: '',
					clave_nueva2: '',
				};
				config.form.destroyed = true;

				setTimeout(() => {
					config.form.destroyed = false;
				});
			},
			submit() {
				submit(formRef, state, config);
			},
			rules: {
				clave_actual: [
					{
						required: true,
						message: 'Ingresa contraseña actual'
					},
				],
				clave_nueva: [
					{
						required: true,
						message: 'Ingresa contraseña nueva'
					},
					{
						min: 6,
						message: 'Contraseña debe tener al menos 6 caracteres'
					},
				],
				clave_nueva2: [
					{
						required: true,
						message: 'Confirma contraseña nueva'
					},
					{ validator: validateClave },
				],
			},
		},
	});

	// STATE
	const state = reactive({
		form: {
			model: {
				clave_actual: '',
				clave_nueva: '',
				clave_nueva2: '',
			},
		},
	});

	// EXPOSE
	defineExpose({
		show() {
			config.form.visible = true;
		},
	});
</script>
