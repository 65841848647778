<template>
	<el-sub-menu index="">
		<template #title>
			<el-icon :size="fromMenu ? 26 : 18"><Person24Filled /></el-icon>
			{{ store.state.user.nombre }} {{ store.state.user.apellido }}
		</template>
		<el-menu-item index="" @click="cambiarContrasena">
			<el-icon :size="14"><ChevronRight24Regular /></el-icon>
			<template #title>Cambiar contraseña</template>
		</el-menu-item>
		<el-divider style="margin: 0"></el-divider>
		<el-menu-item index="/usuarios">
			<el-icon :size="14"><ChevronRight24Regular /></el-icon>
			<template #title>Usuarios</template>
		</el-menu-item>
		<el-menu-item index="/perfiles">
			<el-icon :size="14"><ChevronRight24Regular /></el-icon>
			<template #title>Perfiles</template>
		</el-menu-item>
		<el-divider style="margin: 0"></el-divider>
		<el-menu-item index="" @click="cerrarSesion">
			<el-icon :size="14"><ChevronRight24Regular /></el-icon>
			<template #title>Cerrar sesión</template>
		</el-menu-item>
	</el-sub-menu>
</template>

<script setup>
	import { inject } from 'vue';
	import { useStore } from 'vuex';

	const { fromMenu } = defineProps(['fromMenu']);

	// STORE
	const store = useStore();

	// INJECT DESDE INDEX
	const cambiarContrasena = inject('cambiarContrasena');
	const cerrarSesion = inject('cerrarSesion');

	import { ChevronRight24Regular, Person24Filled } from '@vicons/fluent';
</script>
