import module from './module';

export default module({
	name: 'perfil',
	state: {
		form: {
			model: {
				nombre: '',
				mailing: 1,
				plantilla: 1,
				destinatario: 1,
				lista: 1,
				segmento: 1,
				seguimiento: 1,
				usuario: 1,
				perfil: 1,
				reporte: 1,
			},
		},
	},
});
