import module from './module';

export default module({
	name: 'destinatario',
	state: {
		form: {
			model: {
				email: '',
			},
		},
	},
});
