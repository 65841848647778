import { createStore } from 'vuex';

import data from './data';
import mailing from './mailing';
import destinatario from './destinatario';
import destinatario_mailings from './destinatario_mailings';
import plantilla from './plantilla';
import seguimiento from './seguimiento';
import usuario from './usuario';
import perfil from './perfil';

export default createStore({
	state: {
		user: user,
		viewport: {
			width: 0,
		},
	},
	getters: {
		tablePagerCount(state) {
			return state.viewport.width < 600 ? 7 : 11;
		},
		tableOptionsWidth(state) {
			return state.viewport.width < 600 ? 120 : 150;
		},
	},
	mutations: {
		setUser(state, data) {
			state.user.nombre = data.nombre;
			state.user.apellido = data.apellido;
		},
	},
	actions: {
		async closeSession(context, params) {
			if (context.state.user.id == params.id) {
				await fetch('/logout', {
					method: 'post',
				});

				window.location = '/';
			}
		},
		async refreshUser(context, params) {
			if (context.state.user.id == params.id) {
				let res = await fetch('/api/usuario/' + params.id);

				if (res.status === 200) {
					res = await res.json();
					context.commit('setUser', res.data);
				} else {
					res = await res.json();
					console.error(res);
				}
			}
		},
	},
	modules: {
		data,
		mailing,
		destinatario,
		destinatario_mailings,
		plantilla,
		seguimiento,
		usuario,
		perfil
	},
});
