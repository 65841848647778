<template>
	<el-menu :router="true" :default-active="path" :collapse="!showSession" @select="emit('select')">
		<el-menu-item index="/dashboard">
			<el-icon :size="26"><Glance24Filled /></el-icon>
			<template #title>Dashboard</template>
		</el-menu-item>
		<el-menu-item index="/mailings">
			<el-icon :size="26"><Mail24Filled /></el-icon>
			<template #title>Mailings</template>
		</el-menu-item>
		<el-menu-item index="/plantillas">
			<el-icon :size="26"><MailTemplate24Filled /></el-icon>
			<template #title>Plantillas</template>
		</el-menu-item>
		<el-sub-menu index="/suscriptores">
			<template #title>
				<el-icon :size="26"><People24Filled /></el-icon>
				<span>Suscriptores</span>
			</template>
			<template v-if="!showSession">
				<el-menu-item-group>
					<template #title><span>Suscriptores</span></template>
					<el-menu-item index="/suscriptores/destinatarios">
						<el-icon :size="14"><ChevronRight24Regular /></el-icon>
						<template #title>Destinatarios</template>
					</el-menu-item>
					<el-menu-item index="/suscriptores/listas">
						<el-icon :size="14"><ChevronRight24Regular /></el-icon>
						<template #title>Listas</template>
					</el-menu-item>
					<el-menu-item index="/suscriptores/segmentos">
						<el-icon :size="14"><ChevronRight24Regular /></el-icon>
						<template #title>Segmentos</template>
					</el-menu-item>
				</el-menu-item-group>
			</template>
			<template v-else>
				<el-menu-item index="/suscriptores/destinatarios">
					<el-icon :size="14"><ChevronRight24Regular /></el-icon>
					<template #title>Destinatarios</template>
				</el-menu-item>
				<el-menu-item index="/suscriptores/listas">
					<el-icon :size="14"><ChevronRight24Regular /></el-icon>
					<template #title>Listas</template>
				</el-menu-item>
				<el-menu-item index="/suscriptores/segmentos">
					<el-icon :size="14"><ChevronRight24Regular /></el-icon>
					<template #title>Segmentos</template>
				</el-menu-item>
			</template>
		</el-sub-menu>
		<el-sub-menu index="/seguimiento">
			<template #title>
				<el-icon :size="26"><TextBulletListSquare24Filled /></el-icon>
				<span>Seguimiento</span>
			</template>
			<template v-if="!showSession">
				<el-menu-item-group>
					<template #title><span>Seguimiento</span></template>
					<el-menu-item index="/seguimiento/eventos">
						<el-icon :size="14"><ChevronRight24Regular /></el-icon>
						<template #title>Listado de eventos</template>
					</el-menu-item>
					<el-menu-item index="/seguimiento/envios">
						<el-icon :size="14"><ChevronRight24Regular /></el-icon>
						<template #title>Listado de envíos</template>
					</el-menu-item>
				</el-menu-item-group>
			</template>
			<template v-else>
				<el-menu-item index="/seguimiento/eventos">
					<el-icon :size="14"><ChevronRight24Regular /></el-icon>
					<template #title>Listado de eventos</template>
				</el-menu-item>
				<el-menu-item index="/seguimiento/envios">
					<el-icon :size="14"><ChevronRight24Regular /></el-icon>
					<template #title>Listado de envíos</template>
				</el-menu-item>
			</template>
		</el-sub-menu>
		<el-sub-menu index="/reportes">
			<template #title>
				<el-icon :size="26"><ChartMultiple24Filled /></el-icon>
				<span>Reportes</span>
			</template>
			<template v-if="!showSession">
				<el-menu-item-group>
					<template #title><span>Reportes</span></template>
					<el-menu-item index="/reportes/eventos">
						<el-icon :size="14"><ChevronRight24Regular /></el-icon>
						<template #title>Listado de mailings</template>
					</el-menu-item>
				</el-menu-item-group>
			</template>
			<template v-else>
				<el-menu-item index="/reportes/eventos">
					<el-icon :size="14"><ChevronRight24Regular /></el-icon>
					<template #title>Listado de mailings</template>
				</el-menu-item>
			</template>
		</el-sub-menu>

		<MenuSession :fromMenu="true" v-if="showSession" />
	</el-menu>
</template>

<script setup>
	import { computed } from 'vue';
	import { useRoute } from 'vue-router';

	const { showSession } = defineProps(['showSession']);
	const emit = defineEmits(['select']);

	const route = useRoute();
	const path = computed(() => route.path);

	import MenuSession from '@/components/MenuSession.vue';

	import {
		ChevronRight24Regular,
		Person24Regular,
		Glance24Regular,
		Glance24Filled,
		Mail24Regular,
		Mail24Filled,
		MailTemplate24Regular,
		MailTemplate24Filled,
		TextBulletListSquare24Regular,
		TextBulletListSquare24Filled,
		People24Regular,
		People24Filled,
		ChartMultiple24Regular,
		ChartMultiple24Filled,
		Settings24Regular,
		Settings24Filled,
		Collections24Regular,
		Collections24Filled,
	} from '@vicons/fluent';
</script>