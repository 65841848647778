import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
	{
		path: '/',
		redirect: (to) => {
			return { path: '/dashboard' };
		},
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: () => import('@/views/Dashboard.vue'),
	},
	{
		path: '/mailings',
		name: 'mailings',
		component: () => import('@/views/Mailings.vue'),
	},
	{
		path: '/suscriptores/destinatarios',
		name: 'destinatarios',
		component: () => import('@/views/Destinatarios.vue'),
	},
	{
		path: '/plantillas',
		name: 'plantillas',
		component: () => import('@/views/Plantillas.vue'),
	},
	{
		path: '/seguimiento/eventos',
		name: 'seguimiento',
		component: () => import('@/views/Seguimiento.vue'),
	},
	{
		path: '/usuarios',
		name: 'usuarios',
		component: () => import('@/views/Usuarios.vue'),
	},
	{
		path: '/perfiles',
		name: 'perfiles',
		component: () => import('@/views/Perfiles.vue'),
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;
