<template>
	<el-container>
		<el-header>
			<el-icon class="lines" :size="20" @click="drawer = true">
				<LineHorizontal320Filled />
			</el-icon>
			<img src="@/assets/img/logo_small.png" />
			<div class="space"></div>
			<el-switch v-model="isDark" :active-action-icon="WeatherMoon16Filled" :inactive-action-icon="WeatherSunny16Filled" inline-prompt />
			<el-menu :router="true" :default-active="path" :ellipsis="false" mode="horizontal">
				<MenuSession />
			</el-menu>
		</el-header>
		<el-container>
			<el-aside>
				<el-scrollbar>
					<Menu />
				</el-scrollbar>
			</el-aside>
			<el-main>
				<router-view v-slot="{ Component }">
					<keep-alive>
						<component :is="Component" />
					</keep-alive>
				</router-view>
			</el-main>
		</el-container>
		<el-footer>
			<el-text>Crea Mailings&nbsp;&nbsp;&bull;&nbsp;&nbsp;Comunica&nbsp;&nbsp;&bull;&nbsp;&nbsp;Fideliza&nbsp;&nbsp;&bull;&nbsp;&nbsp;Crece</el-text>
		</el-footer>
	</el-container>

	<el-drawer v-model="drawer" direction="ltr" :with-header="false">
		<el-scrollbar>
			<Menu :showSession="true" @select="drawer = false" />
		</el-scrollbar>
	</el-drawer>

	<CambiarContrasena ref="cambiarContrasenaRef"></CambiarContrasena>
</template>

<script setup>
	import { computed, ref, watch, provide } from 'vue';
	import { useStore } from 'vuex';
	import { useRoute } from 'vue-router';
	import { ElLoading } from 'element-plus';
	import { useDark, useWindowSize } from '@vueuse/core';
	import { Check, Close } from '@element-plus/icons-vue';
	import { confirm } from '@/utils';
	import { WeatherSunny16Filled, WeatherMoon16Filled, LineHorizontal320Filled } from '@vicons/fluent';

	import CambiarContrasena from '@/views/CambiarContrasena.vue';
	import Menu from '@/components/Menu.vue';
	import MenuSession from '@/components/MenuSession.vue';

	// REFS
	const drawer = ref(false);
	const cambiarContrasenaRef = ref(null);

	// ISDARK & PATH
	const isDark = useDark();
	const route = useRoute();
	const path = computed(() => route.path);

	// VIWEPORT
	const store = useStore();
	const { width } = useWindowSize();
	store.state.viewport.width = width;

	// CAMBIAR CONTRASEÑA
	const cambiarContrasena = () => {
		cambiarContrasenaRef.value.show();
	};

	// CERRAR SESION
	const cerrarSesion = () => {
		confirm('¿Desea cerrar sesión?', async () => {
			ElLoading.service();

			await fetch('/logout', {
				method: 'post',
			});

			window.location = '/';
		});
	};

	// PROVIDE HACIA MENU Y MENU SESSION
	provide('cambiarContrasena', cambiarContrasena);
	provide('cerrarSesion', cerrarSesion);
</script>

<style scoped>
	.el-container .el-header {
		--el-header-padding: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--el-border-color-light);
		padding-left: 20px;
	}

	.el-container .el-header .lines {
		cursor: pointer;
	}

	@media (min-width: 800px) {
		.el-container .el-header .lines {
			display: none;
		}
	}

	.el-container .el-header .space {
		flex-grow: 1;
	}

	@media (max-width: 799px) {
		.el-container .el-header .space {
			display: none;
		}
	}

	.el-container .el-header .el-menu {
		border-bottom: none;
	}

	@media (max-width: 799px) {
		.el-container .el-header .el-menu {
			display: none;
		}
	}

	.el-container :deep(.el-header .el-menu > .el-sub-menu .el-sub-menu__title), 
	.el-container :deep(.el-header .el-menu > .el-sub-menu.is-active .el-sub-menu__title) {
		border-bottom: 0px solid transparent;
	}

	@media (max-width: 799px) {
		.el-container .el-header .el-switch {
			margin-right: 20px;
		}
	}

	.el-container .el-container {
		overflow: hidden;
	}

	.el-container .el-container .el-aside {
		height: calc(100dvh - 100px) !important;
		background: var(--el-fill-color-extra-light);
		width: auto;
		border-right: 1px solid var(--el-border-color);
	}

	@media (max-width: 799px) {
		.el-container .el-container .el-aside {
			display: none;
		}
	}

	.el-container .el-main {
		--el-main-padding: 0;
		height: calc(100dvh - 100px) !important;
	}

	.el-container .el-footer {
		--el-footer-padding: 0;
		--el-footer-height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid var(--el-border-color-light);
	}
</style>
