export default {
    namespaced: true,
    state: {
        list: {},
    },
    mutations: {
        setListData(state, data) {
            state.list = data;
        },
    },
    actions: {
        async getAll(context) {
            let res = await fetch("/api/data");
            res = await res.json();
            context.commit("setListData", res.data);
        },
    },
    getters: {},
};
