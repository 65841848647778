import module from './module';

export default module({
	name: 'usuario',
	state: {
		form: {
			model: {
				nombre: '',
				apellido: '',
				email: '',
				usuario: '',
				clave: '',
				perfil_id: '',
				administrador: 0,
				activo: 1,
			},
		},
	},
});
